import React from 'react';

function Footer() {
    return(
        <footer className="bg-white py-4 mt-auto">
            <div className="container">
                <div className="d-flex justify-content-center">
                    <div className="small m-0">Copyright &copy; {new Date().getFullYear()}. <a className="text-decoration-none text-black" href="http://merapikoding.com/">MerapiKoding</a></div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;