// import React from 'react';
import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';

function Header() {
  return (
    <div>
        <Navbar expand="lg" className="navbar-light bg-white py-3">
            <Container className="px-5">
                <Navbar.Brand href="/"><span className="fw-bolder text-primary">MerapiKoding</span></Navbar.Brand>
                <Navbar.Toggle data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></Navbar.Toggle>
                <Navbar.Collapse id="navbarSupportedContent">
                    <Nav variant="pills" className="ms-auto mb-2 mb-lg-0 small fw-bolder">
                        <Nav.Item><Nav.Link href="/">Home</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link href="/Resume">Resume</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link href="/Project">Projects</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link href="/Contact">Contact</Nav.Link></Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </div>
  );
}

export default Header;