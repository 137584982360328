export const project = [
    {
        "id": 15,
        "nama": "Dashboard Digital OutCrops",
        "jenis": "Website",
        "client": "MACHVORY.CO",
        "tanggal": "1 Februari 2024",
        "keterangan": "Dashboard Digital OutCrops adalah aplikasi layanan online berbasis web untuk mengelola data user, data posting, data batuan, dan data report. Di aplikasi ini terdapat fitur single-sign-on bagi pengguna (users) dan fitur unduh (download). Pencarian peta didasarkan pada wilayah administrasi Propinsi dan Kabupaten/Kota sehingga akan didapatkan rekomendasi peta – peta yang tersedia.",
        "gambar": "digicrop_web.png",
        "image": ["digicrop_web.png", "digicrop_web.png", "digicrop_web.png", "digicrop_web.png", "digicrop_web.png"],
        "fitur": [
            "Admin dapat mengelola data laporan user",
            "Admin dapat membanned user",
            "Lihat dan kelola data terrain (medan)",
            "Kelola data posting",
            "Log aktivitas user"
        ],
        "framework": ["ReactJS", "NodeJS", "ExpressJS", "MySQL", "Bootstrap 5", "Google Maps"],
        "url": "http://phedigitaloutcrop.com/"
    },
    {
        "id": 14,
        "nama": "Digital OutCrops",
        "jenis": "Android",
        "client": "MACHVORY.CO",
        "tanggal": "1 Februari 2024",
        "keterangan": "Digital OutCrops adalah aplikasi layanan online berbasis android untuk akses gratis peta – peta kegeologian yang dihasilkan dari survei dan penyelidikan geologi di Pusat Survei Geologi, Badan Geologi, Kementerian Energi dan Sumber Daya Mineral. Di aplikasi ini terdapat fitur single-sign-on bagi pengguna (users) dan fitur unduh (download). Pencarian peta didasarkan pada wilayah administrasi Propinsi dan Kabupaten/Kota sehingga akan didapatkan rekomendasi peta – peta yang tersedia",
        "gambar": "digicrop_mobile.png",
        "image": ["digicrop_mobile.png", "digicrop_mobile.png", "digicrop_mobile.png", "digicrop_mobile.png", "digicrop_mobile.png"],
        "fitur": [
            "Dapat Memposting, memberikan komentar, menyukai postingan, dan melaporkan postingan",
            "Peta Geologi",
            "Dilengkapi Compass untuk membantu pengguna dalam menentukan arah"
        ],
        "framework": ["React Native", "NodeJS", "ExpressJS", "MySQL", "Google SignIn", "Google Maps"],
        "url": ""
    },
    {
        "id": 13,
        "nama": "Directs",
        "jenis": "Website",
        "client": "PT. Javis Teknologi Albarokah",
        "tanggal": "30 Desember 2023",
        "keterangan": "Directs adalah sistem pengendali alat pengatur lalu lintas berbasis website yang berfungsi untuk monitoring dan pengendalian  lampu Apill, menghitung jumlah kendaraan yang melintas, dan ",
        "gambar": "directs_web.png",
        "image": ["directs_web.png", "directs_web.png", "directs_web.png", "directs_web.png", "directs_web.png"],
        "fitur": [
            "Monitoring traffic light",
            "Monitoring sistem kelistrikan dan kerusakan yang terjadi pada lampu lalu lintas",
            "Mengelola data kendaraan prioritas yang melintas",
            "Menampilkan data jumlah kendaraan per kaki simpang",
            "Dapat streaming kamera secara real-time",
            "Cetak laporan jumlah kendaraan, riwayat kelistrikan, dan riwayat perawatan"
        ],
        "framework": ["CodeIgniter", "Bootstrap 5", "MySQL", "MQTT", "Google Maps", "NodeJS"],
        "url": ""
    },
    {
        "id": 12,
        "nama": "Tan&Black Mobile",
        "jenis": "Android",
        "client": "MACHVORY.CO",
        "tanggal": "28 Juli 2023",
        "keterangan": "Tan&Black Mobile adalah aplikasi monitoring dan kontrol suhu sangrai biji kopi secara real-time demi mendapatkan hasil sangrai yang terbaik",
        "gambar": "tnb_mobile.png",
        "image": ["tnb_mobile.png", "tnb_mobile.png", "tnb_mobile.png", "tnb_mobile.png", "tnb_mobile.png"],
        "fitur": [
            "Monitoring dan kontrol suhu sangrai biji kopi secara real-time demi mendapatkan hasil sangrai terbaik",
            "Menyimpan data log sangrai kopi sebelumnya untuk digunakan lagi dengan pengaturan yang sama",
            "Mempunyai tampilan yang responsive ke semua perangkat"
        ],
        "framework": ["React Native", "NodeJS", "ExpressJS", "MySQL", "MQTT"],
        "url": ""
    },
    {
        "id": 11,
        "nama": "Dashboard Tan&Black",
        "jenis": "Android",
        "client": "MACHVORY.CO",
        "tanggal": "29 Mei 2023",
        "keterangan": "Dashboard Tan&Black adalah website monitoring dan kontrol suhu sangrai biji kopi secara real-time demi mendapatkan hasil sangrai yang terbaik",
        "gambar": "tnb_web.png",
        "image": ["tnb_web.png", "tnb_web.png", "tnb_web.png", "tnb_web.png", "tnb_web.png"],
        "fitur": [
            "Monitoring dan kontrol suhu sangrai biji kopi secara real-time demi mendapatkan hasil sangrai terbaik",
            "Menyimpan data log sangrai kopi sebelumnya untuk digunakan lagi dengan pengaturan yang sama",
            "Mempunyai tampilan yang responsive ke semua perangkat"
        ],
        "framework": ["ReactJS", "NodeJS", "ExpressJS", "MySQL", "MQTT", "Bootstrap 5"],
        "url": ""
    },
    {
        "id": 10,
        "nama": "Smart Home",
        "jenis": "Android",
        "client": "MACHVORY.CO",
        "tanggal": "1 Mei 2023",
        "keterangan": "Aplikasi Smart Home dibuat untuk memudahkan pengguna dalam menghidupkan dan mematikan lampu, mengatur kecerahan lampu, dan melihat kerusakan pada lampu. Aplikasi ini dibuat menggunakan react native dan expressjs",
        "gambar": "smart_home.png",
        "image": ["smart_home.png", "smart_home.png", "smart_home.png", "smart_home.png", "smart_home.png"],
        "fitur": [
            "Dapat mengatur kecerahan lampu yang terhubung ke perangkat",
            "Mengelola log lampu jika ada kerusakan",
            "Mematikan dan menghidupkan lampu dengan tombol switch pada aplikasi"
        ],
        "framework": ["React Native", "NodeJS", "ExpressJS", "MySQL", "MQTT"],
        "url": ""
    },
    {
        "id": 9,
        "nama": "Undangan Nikah",
        "jenis": "Android",
        "client": "Cahyo",
        "tanggal": "16 September 2023",
        "keterangan": "Undangan pernikahan dibuat dengan tampilan yang responsive dan menggunakan animate on scoll yang membuat tampilan menjadi lebih menarik dan interaktif dengan pengguna",
        "gambar": "undangan_web.png",
        "image": ["undangan_web.png", "undangan_web.png", "undangan_web.png", "undangan_web.png", "undangan_web.png"],
        "fitur": [
            "Memiliki tampilan yang responsive ke semua perangkat",
            "Menampilkan alamat lokasi dengan google maps",
            "Pengunjung dapat mengirim ucapan dan balasan"
        ],
        "framework": ["NodeJS", "ExpressJS", "MySQL", "Bootstrap 5"],
        "url": ""
    },
    {
        "id": 8,
        "nama": "Monitoring APJ Interkoneksi",
        "jenis": "Android",
        "client": "PT. Javis Teknologi Albarokah",
        "tanggal": "15 Mei 2023",
        "keterangan": "Monitoring APJ Interkoneksi dibuat untuk monitoring alat penerangan jalan secara realtime, terdapat beberapa fitur seperti notifikasi jika terdapat kerusakan pada alat penerangan jalan, mengatur jadwal nyala lampu dan kecerahan lampu, dimming lampu, dan melihat kelistrikan pada perangkat.",
        "gambar": "pju_mobile.png",
        "image": ["pju_mobile.png", "pju_mobile.png", "pju_mobile.png", "pju_mobile.png", "pju_mobile.png"],
        "fitur": [
            "Mengatur jadwal nyala alat penerangan jalan",
            "Menampilkan informasi lokasi alat penerangan jalan yang terpasang dan status (online atau offline)",
            "Mengatur kecerahan lampu jalan",
            "Mengelola data spesifikasi alat penerangan jalan",
            "Monitoring data kelistrikan dan informasi lampu"
        ],
        "framework": ["React Native", "NodeJS", "ExpressJS", "MySQL", "MQTT", "Google Maps"],
        "url": ""
    },
    {
        "id": 7,
        "nama": "Smart DIS",
        "jenis": "Website",
        "client": "PT. Javis Teknologi Albarokah",
        "tanggal": "3 November 2022",
        "keterangan": "Smart DIS adalah sistem pengelolaan Display Information System yang terigrasi dengan sistem lampu lalu lintas. Smart DIS dibuat menggunakan ReactJS dan ExpressJS dengan database Redis. Smart Dis berfungsi untuk mengelola informasi dan konten display, menerima dan menampilkan countdown ketika lampu akan hijau, dan memonitoring informasi yang sedang tampil secara real-time.",
        "gambar": "dis_web.png",
        "image": ["dis_web.png", "dis_web.png", "dis_web.png", "dis_web.png", "dis_web.png"],
        "fitur": [
            "Mengelola daftar perangkat display",
            "Mengatur konten yang akan ditampilkan",
            "Countdown yang terintegrasi dengan lampu lalu lintas",
            "Mengatur daftar user"
        ],
        "framework": ["ReactJS", "NodeJS", "ExpressJS", "Redis", "MQTT", "Bootstrap 5"],
        "url": ""
    },
    {
        "id": 6,
        "nama": "Dashboard APJ Interkoneksi",
        "jenis": "Website",
        "client": "PT. Javis Teknologi Albarokah",
        "tanggal": "30 September 2022",
        "keterangan": "Dashboard APJ Interkoneksi adalah sistem monitoring dan pengelolaan alat penerangan jalan berbasis web yang dibuat menggunakan codeigniter 3. APJ mememiliki beberapa fitur seperti dimming lampu, mengatur kecerahan lampu jalan, mengatur jadwal nyala lampu, melihat laporan kelistrikan perangkat, cetak laporan, dan mendeteksi kerusakan pada alat penerangan jalan.",
        "gambar": "pju_web.png",
        "image": ["pju_web.png", "pju_web.png", "pju_web.png", "pju_web.png", "pju_web.png"],
        "fitur": [
            "Mengatur jadwal nyala alat penerangan jalan",
            "Menampilkan informasi lokasi alat penerangan jalan yang terpasang dan status (online atau offline)",
            "Mengatur kecerahan lampu jalan",
            "Mengelola data spesifikasi alat penerangan jalan",
            "Monitoring data kelistrikan dan informasi lampu",
            "Cetak laporan nyala lampu dan konsumsi daya perbulan"
        ],
        "framework": ["CodeIgniter 3", "Bootstrap", "NodeJS", "MySQL", "MQTT", "Google Maps", "Bootstrap 3"],
        "url": "https://pju.javis.co.id/"
    },
    {
        "id": 5,
        "nama": "PTIS Mobile",
        "jenis": "Android",
        "client": "PT. Javis Teknologi Albarokah",
        "tanggal": "23 Mei 2022",
        "keterangan": "Public Transport Information System yang dibangun menggunakan React Native Android dengan tracking secara real-time adalah solusi teknologi yang inovatif dan berpotensi memberikan manfaat yang besar dalam transportasi publik. Penggunaan React Native memungkinkan pengembangan aplikasi yang efektif dan efisien, dengan kemampuan untuk mengoptimalkan kinerja aplikasi dan memberikan pengalaman pengguna yang baik. Aplikasi Android dikembangkan untuk memperluas jangkauan pengguna. Penggunaan IoT memungkinkan tracking secara real-time dan dapat meningkatkan kinerja transportasi publik secara keseluruhan. Dengan menggunakan sensor dan aktuator, sistem ini dapat mendeteksi keberadaan kendaraan dan pengguna, serta memantau kondisi lalu lintas untuk memberikan pengalaman yang lebih aman dan efisien. Dalam pengembangan sistem Public Transport System ini, web developer akan membangun aplikasi dengan mengintegrasikan teknologi React JS, Android, dan IoT",
        "gambar": "ptis_mobile.png",
        "image": ["ptis_mobile.png", "ptis_mobile.png", "ptis_mobile.png", "ptis_mobile.png", "ptis_mobile.png"],
        "fitur": [
            "Menampilkan daftar halte terdekat dari pengguna",
            "Menampilkan jadwal keberangkatan bus",
            "Melihat lokasi bus secara real-time",
            "Menampilkan Estimasi waktu dan jarak bus dari halte secara real-time"
        ],
        "framework": ["React Native", "NodeJS", "ExpressJS", "MySQL", "MQTT", "Google Maps"],
        "url": ""
    },
    {
        "id": 4,
        "nama": "Dashboard PTIS",
        "jenis": "Website",
        "client": "PT. Javis Teknologi Albarokah",
        "tanggal": "2 Januari 2022",
        "keterangan": "Public Transport Information System yang dibangun menggunakan React JS berbasis web dengan tracking secara real time adalah solusi teknologi yang inovatif dan berpotensi memberikan manfaat yang besar dalam transportasi publik. Penggunaan React JS memungkinkan pengembangan aplikasi yang efektif dan efisien, dengan kemampuan untuk mengoptimalkan kinerja aplikasi dan memberikan pengalaman pengguna yang baik. Pada saat yang sama, aplikasi Android juga dapat dikembangkan untuk memperluas jangkauan pengguna. Penggunaan IoT memungkinkan tracking secara real-time dan dapat meningkatkan kinerja transportasi publik secara keseluruhan. Dengan menggunakan sensor dan aktuator, sistem ini dapat mendeteksi keberadaan kendaraan dan pengguna, serta memantau kondisi lalu lintas untuk memberikan pengalaman yang lebih aman dan efisien. Dalam pengembangan sistem Public Transport System ini, web developer akan membangun aplikasi dengan mengintegrasikan teknologi React JS, Android, dan IoT",
        "gambar": "ptis_web.png",
        "image": ["ptis_web.png", "ptis_web.png", "ptis_web.png", "ptis_web.png", "ptis_web.png"],
        "fitur": [
            "Tracking lokasi bus secara real-time",
            "Melihat jadwal bus secara real-time (jarak dan estimasi sampai ke halte berikutnya)",
            "Mengelola data Bus",
            "Mengelola konten Display",
            "Mengelola data Halte",
            "Mengelola data Trayek Bus"
        ],
        "framework": ["ReactJS", "NodeJS", "ExpressJS", "MySQL", "MQTT", "Google Maps", "Bootstrap 5"],
        "url": "https://ptis.javis.co.id/"
    },
    {
        "id": 3,
        "nama": "Company Profile",
        "jenis": "Website",
        "client": "PT. Javis Teknologi Albarokah",
        "tanggal": "25 April 2022",
        "keterangan": "Pembuatan web perusahaan menggunakan framework codeigniter dengan database mysql yang memiliki fitur pengelolaan data perusahaan, monitoring daftar pengunjung, dan pengelolaan tampilan website.",
        "gambar": "web_javis.png",
        "image": ["web_javis.png", "web_javis.png", "web_javis.png", "web_javis.png", "web_javis.png"],
        "fitur": [
            "Mengelola informasi pengunjung (Visitor)",
            "Mengelola data informasi perusahaan",
            "Mengelola daftar produk",
            "Mengelola daftar divisi dan karir yang tersedia",
            "Mengelola data User"
        ],
        "framework": ["CodeIgniter 3", "MySQL", "Bootstrap 5"],
        "url": "https://www.javis.co.id"
    },
    {
        "id": 2,
        "nama": "Sistem Manajemen KPI",
        "jenis": "Website",
        "client": "PT. Javis Teknologi Albarokah",
        "tanggal": "03 Desember 2021",
        "keterangan": "Sistem Manajemen KPI adalah sistem pelaporan Key Performance Indicator (KPI) untuk mempresentasikan kinerja dan kualitas pekerjaan setiap bulannya. Sistem Manajemen KPI dibuat menggunakan framework codeigniter 4 dengan database mysql.",
        "gambar": "kpi_web.png",
        "image": ["kpi_web.png", "kpi_web.png", "kpi_web.png", "kpi_web.png", "kpi_web.png"],
        "fitur": [
            "Mengelola data PIC",
            "Mengelola KPI setiap project yang dibuat",
            "cetak laporan KPI"
        ],
        "framework": ["CodeIgniter 4", "MySQL", "Bootstrap 4", "AdminLTE 3"],
        "url": "https://kpi-rnd.javis.co.id/"
    },
    {
        "id": 1,
        "nama": "Pendaftaran Santri Baru",
        "jenis": "Website",
        "client": "Pondok Pesantren Bilal Bin Rabah Sukoharjo",
        "tanggal": "2 Februari 2021",
        "keterangan": "website pendaftaran santri baru pada pondok pesantren bilal bin rabah sukoharjo berfungsi untuk mengelola calon santri yang mendaftar, sistem ini memiliki 2 hak akses yaitu pendaftar dan admin. Pendaftar dapat melakukan pendaftaran, mengirim bukti pembayaran, mendapat nomor ujian dan link grup whatsapp, dan melihat hasil ujian. Sedangkan Admin dapat konfirmasi pendaftaran dan pembayaran, mengganti banner, mengatur pendaftaran dibuka atau ditutup, dan konfirmasi kelulusan calon santri.",
        "gambar": "psb_web.png",
        "image": ["psb_web.png", "psb_web.png", "psb_web.png", "psb_web.png", "psb_web.png"],
        "fitur": [
            "Melakukan pendaftaran dengan memasukkan data diri dan pas foto",
            "Konfirmasi pembayaran dengan mengirim bukti pendaftaran dan divalidasi oleh admin",
            "Cetak kartu ujian",
            "Kelola tahun ajaran dan banner",
            "Admin dapat mengelola calon santri yang diterima dan tidak",
            "Calon santri dapat melihat informasi penerimaan dan masuk grup whatsapp"
        ],
        "framework": ["CodeIgniter 4", "MySQL", "Bootstrap 4", "AdminLTE 3"],
        "url": ""
    },
]