import React, { useEffect } from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { project } from '../data';

function Project() {
    useEffect(() => {
        document.title = "MerapiKoding | Project";
    }, []);
    return (
        <div>
            <main className="flex-shrink-0">
                <Header />
                {/* Projects Section*/}
                <section className="py-5">
                    <div className="container px-5 mb-5">
                        <div className="text-center mb-5">
                            <h1 className="display-5 fw-bolder mb-0">
                                <span className="text-gradient d-inline">Projects</span>
                            </h1>
                        </div>
                        <div className="row gx-5 justify-content-center">
                            {!!project && project ? (
                                project.map((item, i) => (
                                    <div className="col-lg-4 col-md-6 portfolio-item mt-2">
                                        <div class="card">
                                            <img src={require('../image/tumbnail/' + item.gambar)} class="card-img-top" alt="..." />
                                            <div class="card-body">
                                                <h5 className="fw-bolder text-center">{item.nama}</h5>
                                                <p style={{ textAlign: 'justify' }} className="card-text">{`${item.keterangan.slice(0, 150)}...`}</p>
                                                <a href={"/DetailProject/" + i}class="btn btn-primary">Detail</a>
                                            </div>
                                        </div>
                                    </div>
                                ))) : (<div>Tidak ada data</div>)}
                        </div>
                    </div>
                </section>
                {/* Call to action section*/}
                <section className="py-5 bg-gradient-primary-to-secondary text-white">
                    <div className="container px-5 my-5">
                        <div className="text-center">
                            <h2 className="display-4 fw-bolder mb-4">
                                Ingin membuat website atau aplikasi?
                            </h2>
                            <a className="btn btn-outline-light btn-lg px-5 py-3 fs-6 fw-bolder" href="/contact">
                                Hubungi Saya
                            </a>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    );
}

export default Project;
