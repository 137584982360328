import React, { useEffect } from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';

function Contact() {
  useEffect(() => {
    document.title = "MerapiKoding | Contact";
  }, []);
  return (
    <div>
        <main className="flex-shrink-0" style={{minHeight: "100vh"}}>
            <Header />
            <section className="py-5">
                <div className="container px-5">
                    {/* Contact form*/}
                    <div className="bg-light rounded-4 py-5 px-4 px-md-5">
                    <div className="text-center mb-5">
                        <div className="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 mb-3">
                        <i className="bi bi-envelope" />
                        </div>
                        <h1 className="fw-bolder">Hubungi Saya</h1>
                        <p className="lead fw-normal text-muted mb-0">Silahkan hubungi saya jika ada yang ingin ditanyakan</p>
                    </div>
                    <div className="row gx-5 justify-content-center">
                            <div className="col-lg-6 ">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d247.07879964312554!2d110.42925198431666!3d-7.762142958015496!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e7a5bb1c0fdb4cb%3A0x4a1fe7b1724b56a5!2sJl.%20Satria%20II%20No.199%2C%20Denokan%2C%20Maguwoharjo%2C%20Kec.%20Depok%2C%20Kabupaten%20Sleman%2C%20Daerah%20Istimewa%20Yogyakarta%2055282!5e0!3m2!1sid!2sid!4v1667652692789!5m2!1sid!2sid" width="100%" height="300" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                            </div>
                            <div className="col-lg-6">
                                <div>
                                    <h5><i className="bx bx-envelope" /> Email</h5>
                                    <p>merapikoding@gmail.com</p>
                                </div>
                                <div>
                                    <h5><i className="bx bx-phone-call" /> Telepon</h5>
                                    <p>+62 889 7018 6806</p>
                                </div>
                                <div>
                                    <h5><i className="bx bx-map" /> Alamat</h5>
                                    <p>Jl. Satria II No.199, Denokan, Maguwoharjo, Kec. Depok, Kabupaten Sleman, Daerah Istimewa Yogyakarta</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <Footer />
    </div>
  );
}

export default Contact;
