import React, { useEffect } from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import resume from '../pdf/resume.pdf';

function Resume() {
  useEffect(() => {
    document.title = "MerapiKoding | Resume";
  }, []);
  return (
    <div>
        <main className="flex-shrink-0">
            <Header />
            <div className="container px-5 my-5">
                <div className="text-center mb-5">
                    <h1 className="display-5 fw-bolder mb-0">
                    <span className="text-gradient d-inline">Resume</span>
                    </h1>
                </div>
                <div className="row gx-5 justify-content-center">
                    <div className="col-lg-11 col-xl-9 col-xxl-8">
                    {/* Experience Section*/}
                    <section>
                        <div className="d-flex align-items-center justify-content-between mb-4">
                        <h2 className="text-primary fw-bolder mb-0">Experience</h2>
                        {/* Download resume button*/}
                        {/* Note: Set the link href target to a PDF file within your project*/}
                        <a className="btn btn-primary px-4 py-3" type='button' href={resume} download="Resume_Rival-Dwiky-Indrawan">
                            <div className="d-inline-block bi bi-download me-2" />
                            Download Resume
                        </a>
                        </div>
                        {/* Experience Card 1*/}
                        <div className="card shadow border-0 rounded-4 mb-5">
                            <div className="card-body p-5">
                                <div className="row align-items-center gx-5">
                                    <div className="col text-center text-lg-start mb-4 mb-lg-0">
                                        <div className="bg-light p-4 rounded-4">
                                        <div className="text-primary fw-bolder mb-2">
                                            2021 - Present
                                        </div>
                                        <div className="small fw-bolder">Web Developer</div>
                                        <div className="small text-muted">PT Javis Teknologi Albarokah</div>
                                        <div className="small text-muted">Yogyakarta</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <ul>
                                            <li>Berhasil membuat lebih dari 5 aplikasi web-based perusahaan dalam kurun waktu 2 tahun</li>
                                            <li>Melakukan update aplikasi web-based perusahaan</li>
                                            <li>Memperbaiki bug dari situs web yang dan menerapkan pengingkatan signifikan dalam fungsionalitas dan kecepatan web</li>
                                            <li>Bertanggung jawab dalam pengembangan aplikasi IoT (Internet of Things) berbasis Web</li>
                                            <li>Bertanggung jawab dalam pengembangan aplikasi berbasiss android</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Experience Card 2*/}
                        <div className="card shadow border-0 rounded-4 mb-5">
                            <div className="card-body p-5">
                                <div className="row align-items-center gx-5">
                                    <div className="col text-center text-lg-start mb-4 mb-lg-0">
                                        <div className="bg-light p-4 rounded-4">
                                            <div className="text-primary fw-bolder mb-2">2023 - Present</div>
                                            <div className="small fw-bolder">Freelancer</div>
                                            <div className="small text-muted">MerapiKoding</div>
                                            <div className="small text-muted">Yogyakarta</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <ul>
                                            <li>Dipercaya lebih dari 3 (tiga) klien dalam proses pembuatan dan pengembangan web dan android</li>
                                            <li>Bertanggung jawab dalam pengembangan aplikasi IoT berbasis android</li>
                                            <li>Mahir dalam melakukan pengolahan data menggunakan Restfull API</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Education Section*/}
                    <section>
                        <h2 className="text-primary fw-bolder mb-4">Education</h2>
                        {/* Education Card 1*/}
                        <div className="card shadow border-0 rounded-4 mb-5">
                            <div className="card-body p-5">
                                <div className="row align-items-center gx-5">
                                <div className="col text-center text-lg-start mb-4 mb-lg-0">
                                    <div className="bg-light p-4 rounded-4">
                                    <div className="text-secondary fw-bolder mb-2">
                                        2017 - 2021
                                    </div>
                                    <div className="mb-2">
                                        <div className="small fw-bolder">Universitas Sahid Surakarta</div>
                                    </div>
                                    <div className="fst-italic">
                                        <div className="small text-muted">S1-Informatika</div>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <ul>
                                        <li>membuat sistem penerimaan santri baru berbasis web menggunakan CodeIgniter</li>
                                        <li>Membuat sistem pengolahan data perjalanan dinas pada Diskominfo Boyolali menggunakan CodeIgniter</li>
                                        <li>Membuat web company profile untuk sekolah</li>
                                    </ul>
                                </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Divider*/}
                    <div className="pb-5" />
                    {/* Skills Section*/}
                    <section>
                        {/* Skillset Card*/}
                        <h2 className="text-primary fw-bolder mb-4">Skills</h2>
                        <div className="card shadow border-0 rounded-4 mb-5">
                            <div className="card-body p-5">
                                {/* FrameWork*/}
                                <div className="mb-5">
                                    <div className="d-flex align-items-center mb-4">
                                        <div className="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 me-3">
                                        <i className="bi bi-tools" />
                                        </div>
                                        <h3 className="fw-bolder mb-0">
                                        <span className="text-gradient d-inline">
                                            Framework
                                        </span>
                                        </h3>
                                    </div>
                                    <div className="row row-cols-1 row-cols-md-3 mb-4">
                                        <div className="col mb-4 mb-md-0">
                                        <div className="d-flex align-items-center bg-light rounded-4 p-3 h-100">
                                            ExpressJS
                                        </div>
                                        </div>
                                        <div className="col mb-4 mb-md-0">
                                        <div className="d-flex align-items-center bg-light rounded-4 p-3 h-100">
                                            ReactJS
                                        </div>
                                        </div>
                                        <div className="col">
                                        <div className="d-flex align-items-center bg-light rounded-4 p-3 h-100">
                                            NextJS
                                        </div>
                                        </div>
                                    </div>
                                    <div className="row row-cols-1 row-cols-md-3">
                                        <div className="col mb-4 mb-md-0">
                                        <div className="d-flex align-items-center bg-light rounded-4 p-3 h-100">
                                            React Native
                                        </div>
                                        </div>
                                        <div className="col mb-4 mb-md-0">
                                        <div className="d-flex align-items-center bg-light rounded-4 p-3 h-100">
                                            CodeIgniter
                                        </div>
                                        </div>
                                        <div className="col">
                                        <div className="d-flex align-items-center bg-light rounded-4 p-3 h-100">
                                            Laravel
                                        </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Database */}
                                <div className="mb-5">
                                    <div className="d-flex align-items-center mb-4">
                                        <div className="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 me-3">
                                        <i className="fas fa-database" />
                                        </div>
                                        <h3 className="fw-bolder mb-0">
                                        <span className="text-gradient d-inline">
                                            Database
                                        </span>
                                        </h3>
                                    </div>
                                    <div className="row row-cols-1 row-cols-md-3 mb-4">
                                        <div className="col mb-4 mb-md-0">
                                            <div className="d-flex align-items-center bg-light rounded-4 p-3 h-100">
                                                MySQL
                                            </div>
                                        </div>
                                        <div className="col mb-4 mb-md-0">
                                            <div className="d-flex align-items-center bg-light rounded-4 p-3 h-100">
                                                Redis
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="d-flex align-items-center bg-light rounded-4 p-3 h-100">
                                                MongoDB
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Languages list*/}
                                <div className="mb-0">
                                    <div className="d-flex align-items-center mb-4">
                                        <div className="feature bg-primary bg-gradient-primary-to-secondary text-white rounded-3 me-3">
                                        <i className="bi bi-code-slash" />
                                        </div>
                                        <h3 className="fw-bolder mb-0">
                                        <span className="text-gradient d-inline">Languages</span>
                                        </h3>
                                    </div>
                                    <div className="row row-cols-1 row-cols-md-3 mb-4">
                                        <div className="col mb-4 mb-md-0">
                                        <div className="d-flex align-items-center bg-light rounded-4 p-3 h-100">
                                            HTML
                                        </div>
                                        </div>
                                        <div className="col mb-4 mb-md-0">
                                        <div className="d-flex align-items-center bg-light rounded-4 p-3 h-100">
                                            CSS
                                        </div>
                                        </div>
                                        <div className="col">
                                        <div className="d-flex align-items-center bg-light rounded-4 p-3 h-100">
                                            JavaScript
                                        </div>
                                        </div>
                                    </div>
                                    <div className="row row-cols-1 row-cols-md-3">
                                        <div className="col mb-4 mb-md-0">
                                        <div className="d-flex align-items-center bg-light rounded-4 p-3 h-100">
                                            PHP
                                        </div>
                                        </div>
                                        <div className="col mb-4 mb-md-0">
                                        <div className="d-flex align-items-center bg-light rounded-4 p-3 h-100">
                                            TypeScript
                                        </div>
                                        </div>
                                        <div className="col">
                                        <div className="d-flex align-items-center bg-light rounded-4 p-3 h-100">
                                            Node.js
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    </div>
                </div>
            </div>
        </main>
        <Footer />
    </div>
  );
}

export default Resume;
