import React, { useEffect } from 'react';
import Header from '../layout/Header';
import Footer from '../layout/Footer';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useParams } from 'react-router-dom';
import { project } from '../data';
import resume from '../pdf/resume.pdf';

function DetailProject() {
    const { id } = useParams();
    useEffect(() => {
        document.title = "MerapiKoding | Detail Project";
    }, []);

    return (
        <div>
            <main id="main" className="flex-shrink-0" style={{minHeight: "100vh"}}>
                <Header />
                {/* ======= Portfolio Details Section ======= */}
                <section id="portfolio-details" className="portfolio-details">
                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-lg-8">
                                <Swiper
                                    effect={'coverflow'}
                                    grabCursor={true}
                                    navigation={true}
                                    centeredSlides={true}
                                    slidesPerView={'auto'}
                                    coverflowEffect={{
                                        rotate: 50,
                                        stretch: 0,
                                        depth: 100,
                                        modifier: 1,
                                        slideShadows: true,
                                    }}
                                    pagination={true}
                                    modules={[EffectCoverflow, Pagination, Navigation]}
                                    className="mySwiper"
                                >
                                    {!!project[id].image && project[id].image ? (
                                        project[id].image.map((item, i) => (
                                            <SwiperSlide className='d-flex justify-content-center'>
                                                <img src={require('../image/tumbnail/' + item)} height={500} alt="" />
                                            </SwiperSlide>
                                        ))) : (null)
                                    }
                                </Swiper>
                            </div>
                            <div className="col-lg-4">
                                <div className="portfolio-info">
                                    <h3>Project information</h3>
                                    <ul>
                                        <li>
                                            <strong>Aplication Name</strong> : {project[id].nama}
                                        </li>
                                        <li>
                                            <strong>Category</strong> : {project[id].jenis}
                                        </li>
                                        <li>
                                            <strong>Client</strong> : {project[id].client}
                                        </li>
                                        <li>
                                            <strong>Date Project</strong> : {project[id].tanggal}
                                        </li>
                                        {/* <li>
                                            <a className="btn btn-primary px-4 py-2" type='button' href={resume} download="Resume_Rival-Dwiky-Indrawan">
                                                <div className="d-inline-block bi bi-download me-2" />
                                                Download Documentation
                                            </a>
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="portfolio-description">
                                <div>
                                    <h2>Detail Information</h2>
                                    <p>{project[id].keterangan}</p>
                                </div>
                                <div>
                                    <h4>Fitur</h4>
                                    <ul>
                                        {!!project[id].fitur && project[id].fitur ? (
                                            project[id].fitur.map((fitur, i) => (
                                                <li>{fitur}</li>
                                            ))) : (null)
                                        }
                                    </ul>
                                </div>
                                <div>
                                    <h4>Tools</h4>
                                    <ul className='simbol'>
                                        {!!project[id].framework && project[id].framework ? (
                                            project[id].framework.map((framework, i) => (
                                                <li>{framework} </li>
                                            ))) : (null)
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* End Portfolio Details Section */}
            </main>
            {/* End #main */}
            <Footer />
        </div>
    );
}

export default DetailProject;
