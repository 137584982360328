import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import Home from './page/Home';
import Project from './page/Project';
import Resume from './page/Resume';
import Contact from './page/contact';
import DetailProject from './page/DetailProject';

function App() {
  return (
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Project" element={<Project />} />
          <Route path="/DetailProject/:id" element={<DetailProject />} />
          <Route path="/Resume" element={<Resume />} />
          <Route path="/Contact" element={<Contact />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
